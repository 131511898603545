
const theme = {
  colors: {
    dark: '#000007',
    darkestNavy: '#01050b',
    darkNavy: '#020c1b',
    navy: '#100e17',
    lightNavy: '#181620',
    darkGrey: '#333f58',
    mediumGrey: '#2d3952',
    blueGrey: '#293d5a',
    grey: '#4c5772',
    lightGrey: '#606a86',
    slate: '#C2C2C2',
    lightSlate: '#e8e8f4',
    lightestSlate: '#e9ebf6',
    offWhite: '#dce7ff',
    white: '#fff',
    pink: '#FF647F',
    darkPink: '#643032',
    yellow: '#FFC464',
    darkYellow: '#c28861',
    orange: '#FF9E64',
    darkOrange: '#724233',
    green: '#64ffda',
    darkGreen: '#3d7a56',
    blue: '#03a9f4',
    cyan: '#1BFFFF',
    darkBlue: '#1D7FFC',
    highlight: 'rgba(41, 61, 90, 0.99)',
    transGreen: 'rgba(100, 255, 218, 0.07)',
    transNavy: 'rgba(10, 25, 47, 0.7)',
    shadowNavy: 'rgba(2, 12, 27, 0.9)',
  },

  fonts: {
    Calibre:
      'Calibre, San Francisco, SF Pro Text, -apple-system, system-ui, BlinkMacSystemFont, Roboto, Helvetica Neue, Segoe UI, Arial, sans-serif',
    SFMono: 'SF Mono, Fira Code, Fira Mono, Roboto Mono, Lucida Console, Monaco, monospace',
  },

  fontSizes: {
    xsmall: '12px',
    smallish: '13px',
    small: '14px',
    medium: '16px',
    large: '18px',
    xlarge: '20px',
    xxlarge: '22px',
    h3: '32px',
  },

  easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  transition: 'all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1)',

  borderRadius: '2px',
  headerHeight: '100px',
  headerScrollHeight: '70px',
  margin: '20px',
  lineHeight: 1.4,

  tabHeight: 42,
  tabWidth: 120,

  gradient: `linear-gradient(0.4turn, #64d6ff, #64ffda)`,

  loaderDelay: `6`,

  hamburgerWidth: 30,
  hamBefore: `top 0.1s ease-in 0.25s, opacity 0.1s ease-in`,
  hamBeforeActive: `top 0.1s ease-out, opacity 0.1s ease-out 0.12s`,
  hamAfter: `bottom 0.1s ease-in 0.25s, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)`,
  hamAfterActive: `bottom 0.1s ease-out, transform 0.22s cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s`,
};

const primaryColor = [theme.colors.cyan, theme.colors.orange, theme.colors.green, theme.colors.pink];
const secondaryColor = [theme.colors.blue, theme.colors.darkOrange, theme.colors.darkGreen, theme.colors.darkPink ];
const date = new Date();
const index = date.getMinutes() % primaryColor.length;
theme.colors.primaryColor = primaryColor[0];
theme.colors.secondaryColor = secondaryColor[0];
export default theme;
